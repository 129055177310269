import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Grid } from '@material-ui/core';
import { fetchTransactionDetails, fetchTransactionDetailsGuest } from '../../redux/payments/actions';
import { getEnvVars } from '../../utils/env-utils';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginTop: '32px'
  },
  solidLine: {
    borderTop: '6px solid #B40F87',
    width: '60px',
    margin: '24px 0px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  actionGrp: {
    maxWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    margin: 0,
    marginBottom: '16px'
  }
}));

const { externalLinks } = getEnvVars();

const PaymentConfirmation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = queryString.parse(location.search);
  const transactionDetails = useSelector(
    state => state.payments.transactionDetails
  );

  useEffect(() => {
    if (params.key) {
      console.log('all params - ', params);
      if(params.flow && params.flow === 'guest'){
        console.log('initiating guest pay');
        dispatch(fetchTransactionDetailsGuest(params.key));
      }else{
        console.log('initiating binder pay');
        dispatch(fetchTransactionDetails(params.key));
      }
    }
  }, [dispatch]);

  return (
    <>
      <Grid item className={classes.root}>
        <Typography variant="h1"> Thank you for your payment!</Typography>
        <div className={classes.solidLine} />
        <div className={classes.content}>
          <Typography color="textSecondary" style={{ margin: '16px 0px 48px' }}>
            You will receive an email confirmation at{' '}
            <Typography color="textPrimary" component="span">
              {transactionDetails.email || 'the provided email.'}
            </Typography>
          </Typography>
          <div className={classes.actionGrp}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              href={externalLinks.memberExp}
              fullWidth
            >
              continue
            </Button>
            <Typography>
              Already set up your account?{' '}
              <a
                href={externalLinks.memberExp}
                style={{ textDecoration: 'none' }}
              >
                Login here
              </a>
            </Typography>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default PaymentConfirmation;
