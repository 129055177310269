import axios from 'axios';
import { resolve } from '../../utils/async-utils';
import { getEnvVars } from '../../utils/env-utils';

const { apiUrl, apiKey, paymentTranUrl } = getEnvVars(window.location.hostname);

export const createGuestCheckoutSession = async (key, successUrl, cancelUrl) =>
  await resolve(
    axios
      .post(`${apiUrl}/api/v1/public/stripe/checkout/create-checkout-session?apikey=${apiKey}`, {
        tempIds: [key],
        successUrl,
        cancelUrl
      })
      .then(res => res.data)
  );

export const createCmsBinderCheckoutSession = async (key, successUrl, cancelUrl) =>
  await resolve(
    axios
      .post(`${paymentTranUrl}/api/v1/public/stripe/checkout/create-checkout-session?apikey=${apiKey}`, {
        tempIds: [key],
        successUrl,
        cancelUrl
      })
      .then(res => res.data)
  );

export const verifyGuest = async ({
  firstName,
  lastName,
  dob,
  zipcode,
  invoiceId
}) =>
  await resolve(
    axios
      .post(`${apiUrl}/api/v1/public/payment/guest/binder?apikey=${apiKey}`, {
        firstName,
        lastName,
        birthDate: dob,
        zip: zipcode,
        invoiceId
      })
      .then(res => res.data)
  );
