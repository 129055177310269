import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DatePickerWrapper from '../Common/FieldWrappers/DatePickerWrapper';
import TextFieldWrapper from '../Common/FieldWrappers/TextFieldWrapper';

const useStyles = makeStyles(() => ({
  formField: {
    marginBottom: '24px'
  },
  picker: {
    '& > div > div > button': {
      display: 'none'
    }
  },
  spacedInput: {
    letterSpacing: '6px'
  }
}));

const GuestPaymentForm = ({ onSubmit, requireInvoiceId = false }) => {
  const classes = useStyles();

  const validate = values => {
    const errors = {};
    if (!values.zipcode) {
      errors.zipcode = 'Required';
    }
    if (!values.firstName) {
      errors.firstName = 'Required';
    }

    if (!values.lastName) {
      errors.lastName = 'Required';
    }

    if (!values.dob) {
      errors.dob = 'Required';
    } else if (
      !values.dob.getTime() ||
      values.dob.getTime() >= new Date().getTime()
    ) {
      errors.dob = 'Invaild Date';
    }

    if (requireInvoiceId && !values.invoiceId) {
      errors.invoiceId = 'Required';
    }

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container direction="column" justify="center">
            <Grid xs={12} item>
              <Field
                fullWidth
                required
                className={classes.formField}
                name="firstName"
                component={TextField}
                type="text"
                label="First Name"
                variant="outlined"
                InputProps={{
                  'data-testid': 'first-name-input'
                }}
              />
            </Grid>
            <Grid xs={12} item>
              <Field
                fullWidth
                required
                className={classes.formField}
                name="lastName"
                component={TextField}
                type="text"
                label="Last Name"
                variant="outlined"
                InputProps={{
                  'data-testid': 'last-name-input'
                }}
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Field
                  className={[classes.formField, classes.picker].join(' ')}
                  variant="inline"
                  type="numeric"
                  inputVariant="outlined"
                  component={DatePickerWrapper}
                  name="dob"
                  label="Date of Birth"
                  placeholder="mm/dd/yyyy"
                  fullWidth
                  required
                  InputProps={{
                    'data-testid': 'dob-input'
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid xs={12} sm={6} item>
              <Field
                className={classes.formField}
                name="zipcode"
                component={TextField}
                label="Zipcode"
                variant="outlined"
                type="numeric"
                fullWidth
                required
                InputProps={{
                  'data-testid': 'zipcode-input'
                }}
              />
            </Grid>
            <Grid xs={12} sm={10} item>
              <Field
                className={classes.formField}
                component={TextFieldWrapper}
                name="invoiceId"
                label={'Invoice ID' + (requireInvoiceId ? '' : ' (optional)')}
                type="numeric"
                variant="outlined"
                required={requireInvoiceId}
                helperText="This is the 12 digit number on the top of your bill"
                InputProps={{
                  classes: {
                    input: classes.spacedInput
                  },
                  'data-testid': 'invoiceId-input'
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={submitting}
            style={{ margin: 0, marginTop: '16px' }}
            fullWidth
          >
            find my account
          </Button>
        </form>
      )}
    ></Form>
  );
};

GuestPaymentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  requireInvoiceId: PropTypes.bool
};

export default GuestPaymentForm;
