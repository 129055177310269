import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Collapse,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularStepProgress from '../Common/CircularStepProgress/CircularStepProgress';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '16px',
    margin: '16px 0px',
    borderRadius: 0,
    position: 'absolute',
    top: '74px',
    zIndex: 999
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-end'
  },
  currentStep: {
    fontSize: '20px',
    fontWeight: 600
  },
  nextStep: {
    fontSize: '14px',
    fontWeight: 600
  }
});

const MobileStepper = ({ steps, activeStep, getIcon }) => {
  const classes = useStyles();
  const [stepsCollapsed, setStepsCollapsed] = useState(true);

  const getProgressLabel = (currentStep, numberOfSteps) =>
    `${currentStep} OF ${numberOfSteps}`;

  const getProgressValue = (currentStep, numberOfSteps) => {
    const result = (currentStep / numberOfSteps) * 100;
    return result <= 100 ? result : 100;
  };

  const getCurrentStep = () =>
    activeStep > steps.length ? null : steps[activeStep].label;

  const getNextStep = () =>
    activeStep >= steps.length
      ? null
      : `Next Step: ${steps[activeStep + 1].label}`;

  const toggleCollapse = () => setStepsCollapsed(!stepsCollapsed);

  return (
    <Paper className={classes.root}>
      <div className={classes.main}>
        <div>
          <CircularStepProgress
            value={getProgressValue(activeStep + 1, steps.length)}
            label={getProgressLabel(activeStep + 1, steps.length)}
          />
        </div>
        <div className={classes.textContainer}>
          <Typography className={classes.currentStep} color="secondary">
            {getCurrentStep()}
          </Typography>
          <Typography className={classes.nextStep} color="textSecondary">
            {getNextStep()}
          </Typography>
          <Button
            variant="text"
            color="primary"
            size="small"
            style={{ margin: 0, width: 'unset' }}
            onClick={toggleCollapse}
          >
            {stepsCollapsed ? 'view' : 'hide'} all steps
          </Button>
        </div>
      </div>
      <Collapse in={!stepsCollapsed}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map(({ label }, index) => (
            <Step key={label}>
              <StepLabel icon={getIcon(index)}>
                <Typography
                  style={{
                    color: index === activeStep && '#B40F87',
                    marginLeft: '16px'
                  }}
                  variant="subtitle2"
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Collapse>
    </Paper>
  );
};

MobileStepper.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  getIcon: PropTypes.func.isRequired
};

export default MobileStepper;
