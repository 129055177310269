export const baseApiUrls = {
  dev: 'https://dev-api.ascension.org/onlinepaymentservice',
  qa: 'https://test-api.ascension.org/onlinepaymentservice',
  uat: 'https://uat-api.ascension.org/onlinepaymentservice',
  prod: 'https://prod-api.ascension.org/onlinepaymentservice'
};

export const binderPayGetTransactionUrls = {
  dev: 'https://dev-api.ascension.org/onlinepaymentservice',
  qa: 'https://test-api.ascension.org/onlinepaymentservice',
  uat: 'https://uat-api.ascension.org/onlinepaymentservice',
  prod: 'https://online-payment-service-api.pub.cloud-03.pcf.ascension.org'
};

export const externalLinks = {
  nonProd: {
    memberExp: 'https://honeybee-web-qa.pub.cloud-02.pcf.ascension.org',
    submittingClaim:
      'https://www.ascensionpersonalizedcare.com/clinicians/submitting-a-claim'
  },
  prod: {
    memberExp: 'https://member.ascensionpersonalizedcare.com',
    submittingClaim:
      'https://www.ascensionpersonalizedcare.com/clinicians/submitting-a-claim'
  }
};

export const stripePubKeys = {
  nonProd:
    'pk_test_51J19egCzdYVqjhwjg2dU99ubxgnerMOKNZ5fnSHkb9uLZ47F2hg7CoXd0RLSkYnw3AhnFhoZAcW8j5hR4JFwOSjo00QZcQuD30',
  prod: 'pk_live_51J19egCzdYVqjhwjd3rAwjsQ2rrcjaxbJsApllQNlvp9MT9TkewVqqGe0s9rmeMPZwV8OEZtiYGDyn4MgADGXTYF00K4Vubnxm'
};

export const proxyApiKeys = {
  nonProd: 'R1eyxcoQNjkSJ0X4T04bAf9JCPkStgpA',
  prod: 'o6NEbdiXb0lQOVJyc3jvlJSVfrvILBPs'
};

export const getEnvVars = (host = window.location.host) => {
  switch (true) {
    case host.includes('localhost'):
      return {
        apiUrl: baseApiUrls.qa,
        externalLinks: externalLinks.nonProd,
        stripePubKey: stripePubKeys.nonProd,
        apiKey: proxyApiKeys.nonProd,
        paymentTranUrl: binderPayGetTransactionUrls.qa
      };
    case host.includes('-dev'):
      return {
        apiUrl: baseApiUrls.dev,
        externalLinks: externalLinks.nonProd,
        stripePubKey: stripePubKeys.nonProd,
        apiKey: proxyApiKeys.nonProd,
        paymentTranUrl: binderPayGetTransactionUrls.dev
      };
    case host.includes('-qa'):
      return {
        apiUrl: baseApiUrls.qa,
        externalLinks: externalLinks.nonProd,
        stripePubKey: stripePubKeys.nonProd,
        apiKey: proxyApiKeys.nonProd,
        paymentTranUrl: binderPayGetTransactionUrls.qa
      };
    case host.includes('-uat'):
      return {
        apiUrl: baseApiUrls.uat,
        externalLinks: externalLinks.nonProd,
        stripePubKey: stripePubKeys.nonProd,
        apiKey: proxyApiKeys.nonProd,
        paymentTranUrl: binderPayGetTransactionUrls.uat
      };
    case host.includes('pub.cloud-03.pcf.ascension.org'):
      return {
        apiUrl: baseApiUrls.prod,
        externalLinks: externalLinks.prod,
        stripePubKey: stripePubKeys.prod,
        apiKey: proxyApiKeys.prod,
        paymentTranUrl: binderPayGetTransactionUrls.prod
      };
    default:
      console.error('Failed to get env config!');
      return {};
  }
};
