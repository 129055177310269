import constants from './constants';

export const initialState = {
  transactionDetails: {},
  transactionDetailsError: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.SET_TRANSACTION_DETAILS:
      return {
        ...state,
        transactionDetails: { ...payload },
        transactionDetailsError: null
      };

    case constants.SET_PAYMENT_ERROR:
      return {
        ...state,
        transactionDetailsError: payload
      };

    default:
      return state;
  }
};
