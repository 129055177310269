import React from 'react';
import { Grid, Typography, makeStyles, Button, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import routes from '../../constants/routes';
import useEnv from '../../hooks/useEnv';
import DesktopIcon from '../../assets/icon-online.svg';
import PersonIcon from '../../assets/icon-guest.svg';
import PhoneIcon from '../../assets/icon-assistance.svg';
import MailIcon from '../../assets/icon-envelope.svg';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '64px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0px 64px 0px'
    }
  },
  solidLine: {
    borderTop: '6px solid #B40F87',
    width: '60px',
    margin: '24px 0px 32px',
    [theme.breakpoints.down('sm')]: {
      margin: '24px 0px'
    }
  },
  link: {
    textDecoration: 'none'
  },
  contentRoot: {
    display: 'flex',
    justifyContent: 'center'
  },
  content: {
    maxWidth: '1200px'
  },
  option: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    minHeight: '320px',
    width: '100%',
    textAlign: 'center',
    justifyContent: 'space-around',
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '24px 32px'
  },
  optIcon: {
    maxHeight: '80px',
    margin: '16px 0px'
  },
  optTitle: {
    fontSize: '24px',
    marginBottom: '8px'
  }
}));

const PaymentOptions = () => {
  const classes = useStyles();
  const history = useHistory();
  const { externalLinks } = useEnv();

  const handleMakePaymentClick = () => history.push(routes.GUEST_LANDING);
  const userLang = navigator.language;

 if (userLang.includes('es')){
  return (
    <Grid item className={classes.root}>
      <Typography variant="h1">Opciones de pago</Typography>
      <div className={classes.solidLine} />
      <Typography style={{ marginBottom: '16px' }}>
      Para la facturación del médico: visite Enviar un{' '}
        <a className={classes.link} href={externalLinks.submittingClaim}>
        Reclamación al Seguro
        </a>
        .
      </Typography>
      <div className={classes.contentRoot}>
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.option}>
              <img
                className={classes.optIcon}
                src={DesktopIcon}
                alt="computer icon"
              />
              <Typography
                variant="h1"
                component="h2"
                className={classes.optTitle}
              >
                Pago en línea
              </Typography>
              <Typography variant="subtitle2">
              Inicie sesión en su portal para miembros para pagar con su tarjeta de débito o crédito, tarjeta de débito prepaga, Google Pay o Apple Pay.
              </Typography>
              <Button
                color="primary"
                href={`${externalLinks.memberExp}/payment`}
                target="__blank"
              >
                Pago en línea {'>'}
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.option}>
              <img
                className={classes.optIcon}
                src={PersonIcon}
                alt="guest icon"
              />
              <Typography
                variant="h1"
                component="h2"
                className={classes.optTitle}
              >
                Pago en línea como invitado
              </Typography>
              <Typography variant="subtitle2">
              Realice un pago a su cuenta sin iniciar sesión.  Se le pedirá que proporcione su nombre, fecha de nacimiento y código postal.
              </Typography>
              <Button color="primary" onClick={handleMakePaymentClick}>
              Pago en línea como invitado {'>'}
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.option}>
              <img
                className={classes.optIcon}
                src={PhoneIcon}
                alt="phone icon"
              />
              <Typography
                variant="h1"
                component="h2"
                className={classes.optTitle}
              >
                Asistencia de pago
              </Typography>
              <div>
                <Typography variant="subtitle2" style={{ marginBottom: '8px' }}>
                Para recibir asistencia de pago por teléfono, comuníquese con nuestro departamento de servicio al cliente.
                </Typography>
                <Typography variant="subtitle2" style={{ marginBottom: '8px' }}>
                  <b>833-600-1311</b>
                </Typography>
                <Typography variant="subtitle2">
                De lunes a viernes, de 8:00 a. m. a 6:00 p. m. EST (Hora Estándar del Este)
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.option}>
              <img className={classes.optIcon} src={MailIcon} alt="mail icon" />
              <Typography
                variant="h1"
                component="h2"
                className={classes.optTitle}
              >
                Pago por correo postal
              </Typography>
              <Typography variant="subtitle2" style={{ marginBottom: '8px' }}>
              Envíe un cheque en papel, cheque de caja o giro postal a:
              </Typography>
              <Typography variant="subtitle2">
                <b>US Health and Life Insurance Company</b>
              </Typography>
              <Typography variant="subtitle2">
                <b>PO Box 72152</b>
              </Typography>
              <Typography variant="subtitle2">
                <b>Cleveland, OH 44192</b>
              </Typography>
              <Typography variant="subtitle2" style={{ marginTop: '8px' }}>
              Haga los cheques a nombre de US Health and Life Insurance Company.
              </Typography>
              <Typography variant="subtitle2">
              El número de factura o la identificación de intercambio federal debe incluirse en su cheque.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
 } else {
  return (
    <Grid item className={classes.root}>
      <Typography variant="h1">Member Payment options</Typography>
      <div className={classes.solidLine} />
      <Typography style={{ marginBottom: '16px' }}>
        For Clinician billing: please visit{' '}
        <a className={classes.link} href={externalLinks.submittingClaim}>
          Submitting a Claim
        </a>
        .
      </Typography>
      <div className={classes.contentRoot}>
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.option}>
              <img
                className={classes.optIcon}
                src={DesktopIcon}
                alt="computer icon"
              />
              <Typography
                variant="h1"
                component="h2"
                className={classes.optTitle}
              >
                Online payment
              </Typography>
              <Typography variant="subtitle2">
                Log in to your member portal to pay with your debit or credit
                card, prepaid debit card, Google Pay or Apple Pay.
              </Typography>
              <Button
                color="primary"
                href={`${externalLinks.memberExp}/payment`}
                target="__blank"
              >
                online payment {'>'}
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.option}>
              <img
                className={classes.optIcon}
                src={PersonIcon}
                alt="guest icon"
              />
              <Typography
                variant="h1"
                component="h2"
                className={classes.optTitle}
              >
                Guest online payment
              </Typography>
              <Typography variant="subtitle2">
                Make a payment to your account without logging in. You will be
                asked to provide your name, date of birth and zip code.
              </Typography>
              <Button color="primary" onClick={handleMakePaymentClick}>
                Guest online payment {'>'}
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.option}>
              <img
                className={classes.optIcon}
                src={PhoneIcon}
                alt="phone icon"
              />
              <Typography
                variant="h1"
                component="h2"
                className={classes.optTitle}
              >
                Payment assistance
              </Typography>
              <div>
                <Typography variant="subtitle2" style={{ marginBottom: '8px' }}>
                  For payment assistance via phone, contact our customer service
                  department.
                </Typography>
                <Typography variant="subtitle2" style={{ marginBottom: '8px' }}>
                  <b>833-600-1311</b>
                </Typography>
                <Typography variant="subtitle2">
                  Monday through Friday, 8:00 a.m. to 6:00 p.m. EST
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.option}>
              <img className={classes.optIcon} src={MailIcon} alt="mail icon" />
              <Typography
                variant="h1"
                component="h2"
                className={classes.optTitle}
              >
                Pay by mail
              </Typography>
              <Typography variant="subtitle2" style={{ marginBottom: '8px' }}>
                For member premium payments, send a paper check, cashier{"'"}s
                check or money order to:
              </Typography>
              <Typography variant="subtitle2">
                <b>US Health and Life Insurance Company</b>
              </Typography>
              <Typography variant="subtitle2">
                <b>PO Box 72152</b>
              </Typography>
              <Typography variant="subtitle2">
                <b>Cleveland, OH 44192</b>
              </Typography>
              <Typography variant="subtitle2" style={{ marginTop: '8px' }}>
                Make checks payable to US Health and Life Insurance Company.
              </Typography>
              <Typography variant="subtitle2">
                The invoice number, Group ID and Member ID must be included on your check.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
 }
};

export default PaymentOptions;
