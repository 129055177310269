import axios from 'axios';
import { getEnvVars } from '../../utils/env-utils';
import constants from './constants';

const { apiUrl, apiKey, paymentTranUrl } = getEnvVars(window.location.hostname);

// action creators
export const setTransactionDetails = payload => ({
  type: constants.SET_TRANSACTION_DETAILS,
  payload
});

export const setPaymentError = payload => ({
  type: constants.SET_PAYMENT_ERROR,
  payload
});

export const fetchTransactionDetails = key => dispatch =>
  axios
    .get(`${paymentTranUrl}/api/v1/public/payment-transaction/${key}?apikey=${apiKey}`)
    .then(res => dispatch(setTransactionDetails(res.data)))
    .catch(function(err){
      console.log(err);
      dispatch(fetchTransactionDetailsGuest(key));
    });

export const fetchTransactionDetailsGuest = key => dispatch =>
  axios
    .get(`${apiUrl}/api/v1/public/payment-transaction/${key}?apikey=${apiKey}`)
    .then(res => dispatch(setTransactionDetails(res.data)))
    .catch(err => dispatch(setPaymentError(err.message)));
