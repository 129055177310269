import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Stepper from '../Common/Stepper/Stepper';
import MobileStepper from './MobileStepper';
import CompletedStepIcon from '@material-ui/icons/CheckCircle';
import UncompletedStepIcon from '@material-ui/icons/FiberManualRecord';
import CurrentStepIcon from '@material-ui/icons/RadioButtonChecked';

const steps = [
  { label: 'Shop for plan' },
  { label: 'Select policy' },
  { label: 'Submit application' },
  { label: 'Make initial payment' },
  { label: 'Set up Ascension ID' },
  { label: 'Process payment' },
  { label: 'Access plan' }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '160px'
  },
  completedStepIcon: {
    color: theme.palette.primary.main
  },
  currentStepIcon: {
    color: theme.palette.secondary.main
  },
  uncompletedStepIcon: {
    color: theme.palette.action.active
  }
}));

const PaymentStepper = ({ activeStep }) => {
  const theme = useTheme();
  const classes = useStyles();
  const displayMobileStepper = useMediaQuery(theme.breakpoints.down('sm'));

  const getIcon = step => {
    if (step < activeStep)
      return <CompletedStepIcon className={classes.completedStepIcon} />;

    if (step === activeStep)
      return <CurrentStepIcon className={classes.currentStepIcon} />;

    return <UncompletedStepIcon className={classes.uncompletedStepIcon} />;
  };

  return (
    <Grid item lg={12} xl={8} className={classes.root}>
      {displayMobileStepper ? (
        <MobileStepper
          steps={steps}
          activeStep={activeStep}
          getIcon={getIcon}
        />
      ) : (
        <Stepper steps={steps} activeStep={activeStep} getIcon={getIcon} />
      )}
    </Grid>
  );
};

PaymentStepper.propTypes = {
  activeStep: PropTypes.number
};

PaymentStepper.defaultProps = {
  activeStep: 3
};

export default PaymentStepper;
