import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ascensionTheme } from '@ascension/ui-library';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { store } from '../redux/store';
import appTheme from './App.theme';
import Banner from '../components/Banner/Banner';
import PaymentFlow from '../components/PaymentFlow/PaymentFlow';
import { getEnvVars } from '../utils/env-utils';
import '@ascension/ui-library/dist/fonts/WhitneySSm.css';
import '@ascension/ui-library/dist/fonts/ChronicleSSm.css';

const createdAscensionTheme = createMuiTheme(ascensionTheme);
const createdAppTheme = createMuiTheme(appTheme);

const { stripePubKey } = getEnvVars();

const stripePromise = loadStripe(stripePubKey);

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={createdAscensionTheme}>
        <ThemeProvider theme={createdAppTheme}>
          <BrowserRouter>
            <CssBaseline />
            <Elements stripe={stripePromise}>
              <Banner />
              <PaymentFlow />
            </Elements>
          </BrowserRouter>
        </ThemeProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
