import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import BinderLanding from '../BinderLanding/BinderLanding';
import PaymentConfirmation from '../PaymentConfirmation/PaymentConfirmation';
import routes from '../../constants/routes';
import GuestLanding from '../GuestLanding/GuestLanding';
import PaymentOptions from '../PaymentOptions/PaymentOptions';
import NoPaymentConfirmation from '../NoPaymentConfirmation/NoPaymentConfirmation';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px 120px',
    [theme.breakpoints.down('md')]: {
      padding: '0px 60px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 12px'
    }
  }
}));

const PaymentFlow = () => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.root}
      container
      alignItems="center"
      direction="column"
      wrap="nowrap"
    >
      <Switch>
        <Route exact path={routes.BINDER_LANDING} component={BinderLanding} />
        <Route path={routes.GUEST_LANDING} component={GuestLanding} />
        <Route
          path={routes.PAYMENT_CONFIRMATION}
          component={PaymentConfirmation}
        />
        <Route path={routes.PAYMENT_OPTIONS} component={PaymentOptions} />
        <Route
          path={routes.NO_PAYMENT_NEEDED}
          component={NoPaymentConfirmation}
        />
      </Switch>
    </Grid>
  );
};

export default PaymentFlow;
