import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const CircularStepProgress = ({ label, ...rest }) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        size={80}
        thickness={2}
        color="secondary"
        {...rest}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          style={{ fontWeight: 600 }}
          component="div"
          color="secondary"
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

CircularStepProgress.propTypes = {
  label: PropTypes.string.isRequired
};

export default CircularStepProgress;
