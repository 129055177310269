import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { verifyGuest } from '../../api/payments/payments';
import GuestPaymentForm from './GuestPaymentForm';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '600px',
    background: 'transparent',
    padding: '64px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px'
    }
  },
  infoText: {
    margin: '16px 0px'
  },
  error: {
    padding: '16px'
  }
}));

const GuestLanding = () => {
  const classes = useStyles();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [requireInvoiceId, setRequireInvoiceId] = useState(false);

  const onFindAccountSubmit = async values => {
    const { data, error } = await verifyGuest({ ...values });
    if (error) {
      if (error.response.status === 500) {
        setErrorMessage('We are currently unable to process your request.');
      } else {
        setErrorMessage(error.response.data);
        // Multiple matching users
        if (error.response.status === 409) {
          setRequireInvoiceId(true);
        }
      }
    } else {
      history.push(`/?key=${data.tempId}&flow=guest`);
    }
  };

  return (
    <Grid item className={classes.root}>
      <Typography variant="h1">Guest Payment</Typography>
      <Typography variant="body2" className={classes.infoText}>
        Find your account with your invoice ID or with the information you
        provided during the enrollment process.
      </Typography>
      <Typography
        variant="body2"
        className={classes.infoText}
        style={{ marginBottom: '32px' }}
      >
        If you recently applied for coverage, your member account may not yet be
        available. It can take up to 48 hours to receive your application.
      </Typography>

      <GuestPaymentForm
        onSubmit={onFindAccountSubmit}
        requireInvoiceId={requireInvoiceId}
      />
      {errorMessage && (
        <div className={classes.error}>
          <Typography color="error" style={{ marginBottom: '16px' }}>
            {errorMessage}
          </Typography>
          <Typography color="error">
            If this problem persists call customer service at 833-600-1311
          </Typography>
        </div>
      )}
    </Grid>
  );
};

export default GuestLanding;
