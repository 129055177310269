import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const TextFieldWrapper = ({
  input: { name, onChange, value, ...restInput },
  helperText,
  meta,
  ...rest
}) => {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <TextField
      {...rest}
      name={name}
      helperText={showError ? meta.error || meta.submitError : helperText}
      error={showError}
      inputProps={restInput}
      onChange={onChange}
      value={value === '' ? null : value}
      format="MM/dd/yyyy"
    />
  );
};

TextFieldWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  helperText: PropTypes.string
};

export default TextFieldWrapper;
