import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Grid } from '@material-ui/core';
import { fetchTransactionDetails } from '../../redux/payments/actions';
import { getEnvVars } from '../../utils/env-utils';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    margin: '32px 0px'
  },
  solidLine: {
    borderTop: '6px solid #B40F87',
    width: '60px',
    margin: '24px 0px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    '& > p': {
      marginBottom: '12px'
    }
  },
  actionGrp: {
    maxWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '32px'
  },
  button: {
    margin: 0
  }
}));

const { externalLinks } = getEnvVars();

const NoPaymentConfirmation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = queryString.parse(location.search);
  const { firstName } = useSelector(state => state.payments.transactionDetails);

  useEffect(() => {
    if (params.key) {
      dispatch(fetchTransactionDetails(params.key));
    }
  }, [dispatch]);

  return (
    <>
      <Grid item className={classes.root}>
        <Typography variant="h1">
          You are all set{firstName && `, ${firstName}`}!
        </Typography>
        <div className={classes.solidLine} />
        <div className={classes.content}>
          <Typography color="textSecondary">
            Your amount due is less than $0.50. No payment is required at this
            time.
          </Typography>
          <Typography color="textSecondary">
            Please check your account for future balance information.
          </Typography>
          <Typography color="textSecondary">
            Contact Ascension Personalized Care customer service at{' '}
            <a href="tel:+833-600-1311">833-600-1311</a> with any questions.
          </Typography>
          <div className={classes.actionGrp}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              href={externalLinks.memberExp}
              fullWidth
            >
              continue
            </Button>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default NoPaymentConfirmation;
