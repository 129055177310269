import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';
import ascensionLogo from '../../assets/banner_brand.png';

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    background: '#fff',
    padding: '0px 60px',
    minHeight: '90px',
    borderBottom: '1px solid #9e9e9e',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      padding: '16px 8px'
    }
  }
}));

export const Banner = () => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar}>
      <Link to="/">
        <img src={ascensionLogo} height="44px" alt="brand" />
      </Link>
    </Toolbar>
  );
};

export default withRouter(Banner);
