import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '64px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px'
    }
  },
  solidLine: {
    borderTop: '6px solid #B40F87',
    width: '60px',
    margin: '24px 0px'
  }
}));

const BaseError = ({ title, message }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h1">{title}</Typography>
      <div className={classes.solidLine} />
      <Typography style={{ marginBottom: '16px' }}>{message}</Typography>
      <Typography>
        If this problem persists, call customer service at 833-600-1311.
      </Typography>
    </div>
  );
};

BaseError.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default BaseError;
