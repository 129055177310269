import React from 'react';
import PropTypes from 'prop-types';
import {
  Stepper as MuiStepper,
  Step,
  StepLabel,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  stepper: {
    background: 'transparent',
    margin: '48px 0px',
    width: '100%'
  },
  step: {
    width: '160px',
    padding: 0
  },
  displayTop: {
    position: 'absolute',
    top: '-40px',
    textAlign: 'center',
    width: '100%'
  },
  displayBottom: {
    position: 'absolute',
    top: '40px',
    textAlign: 'center',
    width: '100%'
  }
}));

const Stepper = ({ steps, activeStep, getIcon }) => {
  const classes = useStyles();

  return (
    <MuiStepper
      className={classes.stepper}
      activeStep={activeStep}
      alternativeLabel
    >
      {steps.map(({ label }, index) => {
        return (
          <Step key={label} className={classes.step}>
            <StepLabel icon={getIcon(index)}>
              <Typography
                variant="subtitle2"
                style={{
                  color: index === activeStep && '#B40F87'
                }}
                className={
                  index % 2 !== 0 ? classes.displayTop : classes.displayBottom
                }
              >
                {label}
              </Typography>
            </StepLabel>
          </Step>
        );
      })}
    </MuiStepper>
  );
};

Stepper.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  getIcon: PropTypes.func.isRequired
};

export default Stepper;
