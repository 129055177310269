import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useStripe } from '@stripe/react-stripe-js';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import BinderStepper from '../PaymentStepper/PaymentStepper';
import { fetchTransactionDetails } from '../../redux/payments/actions';
import { createCmsBinderCheckoutSession, createGuestCheckoutSession } from '../../api/payments/payments';
import routes from '../../constants/routes';
import { getDateObj } from '../../utils/date-utils';
import BaseError from '../BaseError/BaseError';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    background: 'transparent',
    padding: '0px 64px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 16px'
    }
  },
  content: {
    maxWidth: '800px'
  },
  solidLine: {
    borderTop: '6px solid #B40F87',
    width: '60px',
    margin: '24px 0px 64px',
    [theme.breakpoints.down('xs')]: {
      margin: '24px 0px'
    }
  },
  subHeader: {
    marginBottom: '24px'
  },
  button: {
    margin: '16px 16px 0px 0px',
    [theme.breakpoints.down('xs')]: {
      margin: '8px 0px'
    }
  },
  error: {
    padding: '16px'
  }
}));

const BinderLanding = ({ history }) => {
  const classes = useStyles();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const params = queryString.parse(history.location.search);
  const {
    transactionDetails: { firstName, planName, dueDate, amountDue },
    transactionDetailsError
  } = useSelector(state => state.payments);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getDisplayDueDate = date => {
    if (!date) return '';

    const dateObj = getDateObj(date);

    return `${dateObj.shortMonth}. ${dateObj.day}, ${dateObj.year}`;
  };

  useEffect(() => {
    if (params.key) {
      dispatch(fetchTransactionDetails(params.key));
    } else {
      history.push(routes.GUEST_LANDING);
    }
  }, [dispatch]);

  const handleLearnAboutOptionsClick = () =>
    history.push(routes.PAYMENT_OPTIONS);

  const handleCheckoutClick = async () => {
    setError(null);
    setLoading(true);

    if (amountDue < 0.5) {
      history.push(`${routes.NO_PAYMENT_NEEDED}/?key=${params.key}`);
      return;
    }

    const { data, error } = await createCmsBinderCheckoutSession(
      params.key,
      `${location.origin}${routes.PAYMENT_CONFIRMATION}/?key=${params.key}`,
      `${location.origin}/?key=${params.key}&res=canceled`
    );

    let sessionId = '';

    if(data && data.sessionId){
      console.log('reading sessionId from createCmsBinderCheckoutSession - ', data.sessionId);
      sessionId = data.sessionId;
    }

    if(error && error.response.status === 404){
      console.info('attempting to fetch data from other service');
      const { data, error } = await createGuestCheckoutSession(
        params.key,
        `${location.origin}${routes.PAYMENT_CONFIRMATION}/?key=${params.key}`,
        `${location.origin}/?key=${params.key}&res=canceled`
      );

      if(data){
        console.log('reading sessionId from  createGuestCheckoutSession - ', data.sessionId);
        sessionId = data.sessionId;
      }
      if(error){
        console.info({ ...error });
        setError('We are currently unable to process your request');
      }
    }

    setLoading(false);

    if (sessionId === '' && error) {
      console.info({ ...error });

      if (error.response.status === 400 && error.response.data) {
        setError(error.response.data);
      }else {
        setError('We are currently unable to process your request');
      }
      return;
    }

    const result = await stripe.redirectToCheckout({
      sessionId: sessionId
    });

    if (result.error) {
      console.log(result.error.message);
    }
  };

  return !transactionDetailsError ? (
    <>
      <BinderStepper />
      <Grid item className={classes.root}>
        <div className={classes.content}>
          <Typography variant="h1">Hi {firstName || 'User'}!</Typography>
          <div className={classes.solidLine} />
          <Typography variant="h3" className={classes.subHeader}>
            Make your first payment to activate your plan.
          </Typography>
          <Typography style={{ margin: '24px 0px' }}>
            To finish your enrollment in your{' '}
            <Typography component="span" color="secondary">
              <b>{planName} </b>
            </Typography>
            plan, you will need to make your first payment.
          </Typography>
          {dueDate && (
            <Typography>
              If no payment is made by{' '}
              <Typography component="span" color="secondary">
                {getDisplayDueDate(dueDate)}
              </Typography>{' '}
              then you may not have coverage.
            </Typography>
          )}
          <div style={{ marginTop: '64px' }}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => handleCheckoutClick()}
              disabled={loading}
            >
              pay my first payment online
            </Button>

            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={handleLearnAboutOptionsClick}
            >
              learn about other payment options
            </Button>
          </div>
        </div>
        {error && (
          <div className={classes.error}>
            <Typography color="error" style={{ marginBottom: '16px' }}>
              {error}
            </Typography>
            <Typography color="error">
              If this problem persists call customer service at 833-600-1311
            </Typography>
          </div>
        )}
      </Grid>
    </>
  ) : (
    <BaseError
      title="We are currently unable locate the requested record."
      message="Please refresh the page to try again."
    />
  );
};

BinderLanding.propTypes = {
  history: PropTypes.object.isRequired
};

export default BinderLanding;
